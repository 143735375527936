@import "~primeng/resources/primeng.min.css";
@import "~primeng/resources/themes/saga-orange/theme.css";
@import "~primeicons/primeicons.css";
@import "~primeflex/primeflex.css";

html,
body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  width: 100%;
  height: 100%;
  font-family: "Poppins", sans-serif;
  background-color: #f4a81d;
}

.displayTable {
  display: table;
  width: 100%;
  height: 100%;
  background: #f4a81d;
}

.displayTableCell {
  display: table-cell;
  vertical-align: top;
  width: 100%;
  height: 100%;
}

.displayTable .btn {
  width: 100%;
  border: none;
  font-size: 16px;
  font-weight: 600;
  padding: 15px 0;
  background: #15cd72;
  color: #ffffff;
  cursor: pointer;
  outline: none;
}

.displayTable .btn:hover {
  opacity: 0.88;
}

.displayTable .btnSecondary {
  background: #eeeeee;
  color: #404040;
}

.formGroup {
  margin-bottom: 20px;
  float: left;
  width: 100%;
}

.formControl {
  width: 100%;
  display: block;
  padding: 15px 15px 14px;
  border: 2px solid #e7e7e7;
  outline: none;
  font-size: 15px;
  color: #444444;
  background: #fcfcfc;
}

.formControl:focus {
  border: 2px solid #d3d3d6;
}

.authBlock {
  margin: 0 auto;
  margin-top: 10px;
  max-width: 400px;
  background: white;
  padding: 30px 40px 10px;
  overflow: hidden;
  -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.04);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.04);
}

.forgotPassword {
  text-align: center;
  margin: -12px 0 15px 0;
  float: left;
  width: 100%;
}

.forgotPassword span {
  color: #f4a81d;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  display: inline-block;
  padding-top: 20px;
}

.redirectToLogin {
  padding: 15px 0 0;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  display: block;
  color: black;
}

.redirectToLogin .redirect {
  cursor: pointer;
  color: black;
  text-decoration: underline;
}